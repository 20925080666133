import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, Link } from "gatsby"

import "../../../../graphql/fragments"

import SEO from "../../../../components/seo"
import Layout from "../../../../components/layout"
import { Breadcrumbs } from "../../../../components/breadcrumbs/breadcrumbs"
import { HeadingBasic } from "../../../../components/heading/heading"
import {
  Stats,
  WpIconlist,
  WpGroup,
} from "../../../../components/utils/render-functions"
import { CoverMain } from "../../../../components/cover-main/cover-main"

// import tollRoadImg from "../../assets/img/img-development-bsd-toll-map.jpg"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const ProjectAccess = ({ location }) => {
  const staticData = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "img_development-main.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      imgThumbnail: file(relativePath: { eq: "imgBanjarWijaya.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 738) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        projectBy(uri: "bsd-city/toll-road") {
          title
          slug
          propertyTypes {
            nodes {
              name
              slug
            }
          }
          blocks {
            name
            ...CoreCoverBlock
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreImageBlock
            ...CoreButtonBlock
            ...CoreShortcodeBlock
            ...CoreGroupBlock
            ...AcfIconListBlock
            ...AcfLabelValueBlock
            ...AcfGalleryBlock
          }
          nearby
          projectCustomfields {
            longitude
            latitude
          }
          translation(language: ID) {
            title
            slug
            propertyTypes {
              nodes {
                name
                slug
              }
            }
            blocks {
              name
              ...CoreCoverBlock
              ...CoreParagraphBlock
              ...CoreHeadingBlock
              ...CoreImageBlock
              ...CoreButtonBlock
              ...CoreShortcodeBlock
              ...CoreGroupBlock
              ...AcfIconListBlock
              ...AcfLabelValueBlock
              ...AcfGalleryBlock
            }
            nearby
            projectCustomfields {
              longitude
              latitude
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
        }
      }
    }
  `)

  const wordPress = staticData?.wordPress?.projectBy?.blocks
  const seo = staticData?.wordPress?.projectBy?.seo

  const cover =
    wordPress &&
    wordPress.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]

  return (
    <Layout location={location} currentLocation={location.pathname} lang="EN">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle}
        twitterTitle={seo?.twitterTitle}
        twitterDescription={seo?.twitterDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={
          cover
            ? cover.attributes.url
            : staticData.imgCoverMain.childImageSharp.fluid
        }
        twitterImg={
          cover
            ? cover.attributes.url
            : staticData.imgCoverMain.childImageSharp.fluid
        }
        img={
          cover
            ? cover.attributes.url
            : staticData.imgCoverMain.childImageSharp.fluid
        }
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      <CoverMain
        h1={
          staticData?.wordPress?.projectBy
            ? staticData?.wordPress?.projectBy?.title
            : "Bus Station"
        }
        img={
          cover
            ? cover?.attributes?.url
            : staticData?.imgCoverMain?.childImageSharp?.fluid
        }
        alt="Bus Station"
        className="cover-md"
      >
        <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
      </CoverMain>

      {wordPress &&
        wordPress.map((item, i) => {
          switch (item.name) {
            case "core/heading":
              return (
                <div key={`heading-${i}`} className="container">
                  <div className={i === 0 ? "py-main" : ""}>
                    <HeadingBasic h2={item.attributes.content} />
                  </div>
                </div>
              )
            case "core/buttons":
              if (
                item.innerBlocks[0].attributes.url &&
                item.innerBlocks[0].attributes.url.split(".").length > 1
              ) {
                return (
                  <div key={`button=${i}`} className="container">
                    <div className={i === 0 ? "py-main" : "pb-main"}>
                      <HeadingBasic>
                        <a
                          href={item.innerBlocks[0].attributes.url}
                          target="_blank"
                          className="btn btn-link fx-underline"
                          rel="noopener noreferrer"
                          key={`${item.innerBlocks[0].attributes.url}-${i}`}
                        >
                          {item.innerBlocks[0].attributes.text} &nbsp;
                          <i className="far fa-external-link"></i>
                        </a>
                      </HeadingBasic>
                    </div>
                  </div>
                )
              } else {
                return (
                  <div key={`button-${i}`} className="container">
                    <Link
                      key={`${item.innerBlocks[0].attributes.url}-${i}`}
                      to={item.innerBlocks[0].attributes.url}
                      className="btn btn-link fx-underline"
                    >
                      {item.innerBlocks[0].attributes.text}
                    </Link>
                  </div>
                )
              }
            case "core/paragraph":
              return (
                <div key={`paragraph-${i}`} className="py-main">
                  <HeadingBasic
                    className="container container-md"
                    text={[item.attributes.content]}
                  />
                </div>
              )
            case "core/group":
              if (item.innerBlocks[0].attributes.content === "Existing Route") {
                return (
                  <section
                    key={`exisiting-route-${i}`}
                    className="py-main section-project-list"
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-md-6">
                          <h2 className="h2">
                            {item.innerBlocks.find(
                              x => x.name === "core/heading"
                            )
                              ? item.innerBlocks.find(
                                  x => x.name === "core/heading"
                                ).attributes.content
                              : "Exisiting Route"}
                          </h2>
                        </div>
                        <div className="col-md-6">
                          {item.innerBlocks?.map((route, i) => {
                            if (route.name === "core/group") {
                              return (
                                <div
                                  key={`route-${i}`}
                                  className="list-project"
                                >
                                  <h3 className="h5">
                                    {route.innerBlocks[0] &&
                                      route.innerBlocks[0].attributes.content.replace(
                                        /(<([^>]+)>)/gi,
                                        ""
                                      )}
                                  </h3>
                                  <p>
                                    {route.innerBlocks[1] &&
                                      route.innerBlocks[1].attributes.content
                                        .split("amp;")
                                        .join("")}
                                  </p>
                                </div>
                              )
                            }
                            return <div key={`empty-block-${i}`}></div>
                          })}
                        </div>
                      </div>
                    </div>
                  </section>
                )
              } else if (
                item.innerBlocks[0].attributes.content === "Future Development"
              ) {
                return (
                  <section
                    key={`future-development-${i}`}
                    className="py-main pt-0 section-project-list"
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-md-6">
                          <h2 className="h2">
                            {item.innerBlocks.find(
                              x => x.name === "core/heading"
                            )
                              ? item.innerBlocks.find(
                                  x => x.name === "core/heading"
                                ).attributes.content
                              : "Exisiting Route"}
                          </h2>
                        </div>
                        <div className="col-md-6">
                          {item.innerBlocks?.map((development, i) => {
                            if (development.name === "core/paragraph") {
                              return (
                                <p key={`development.attributes.content-${i}`}>
                                  {development.attributes.content}
                                </p>
                              )
                            }
                            return <p key={`empty-paragraph-${i}`}></p>
                          })}
                        </div>
                      </div>
                    </div>
                  </section>
                )
              } else {
                return (
                  <div
                    key={`${item.name}-${i}`}
                    className={i === 0 || i === 1 ? "py-main" : "pb-main"}
                  >
                    <WpGroup data={item} />
                  </div>
                )
              }
            case "core/cover":
              if (i > 0) {
                return (
                  <div key={`cover-${i}`} className="pb-main">
                    <img
                      alt="img-bsd-toll-road"
                      src={
                        item.attributes.url ||
                        staticData.imgCoverMain.childImageSharp.fluid
                      }
                      className="img-fluid"
                    />
                  </div>
                )
              } else {
                return <div key={`cover-${i}`}></div>
              }
            case "acf/iconlist":
              return (
                <div
                  key={`iconlist-${i}`}
                  className={
                    i === 0 ? "container py-main" : "container pb-main"
                  }
                >
                  <WpIconlist data={item} />
                </div>
              )
            case "acf/labelvalues":
              return (
                <div key={`${item.name}-${i}`} className="pb-main">
                  <Stats data={item} />
                </div>
              )
            default:
              return <div key={`empty-${i}`}></div>
          }
        })}
    </Layout>
  )
}

export default ProjectAccess
